class RFVTracker {

    constructor(options) {
        var defaults = {
            "recencyScores": [
              [15, 30], [8, 14], [2, 7], [0, 1]
            ],
            "frequencyScores": [
                [1, 2], [3, 6], [7, 12], [13, 1000000]
            ],
            "volumeScores": [
                [1, 4], [5, 10], [11, 20], [21, 1000000]
            ]
        };
        this.params = Object.assign({}, defaults, options || {});
        const storageKey = 'rfvData';
        const sessionTimeout = 30;
        const periodDays = 30;

        this.storageKey = storageKey;
        this.sessionTimeout = sessionTimeout * 60 * 1000;
        this.periodStart = Date.now() - periodDays * 24 * 60 * 60 * 1000;
        this.data = this.loadData();

        this.start();
    }

    start() {
        this.updateSession();
        this.incrementPageViews();

        const metrics = this.getMetrics();
        this.sendMetrics(metrics);

        this.saveData();
    }

    loadData() {
        const savedData = localStorage.getItem(this.storageKey);
        return savedData ? JSON.parse(savedData) : { lastVisit: null, lastSessionTime: null, sessions: [], pageViews: [] };
    }

    saveData() {
        this.data.lastVisit = new Date().toISOString().split("T")[0];
        this.data.sessions = this.data.sessions.filter((timestamp) => timestamp > this.periodStart);
        this.data.pageViews = this.data.pageViews.filter((timestamp) => timestamp > this.periodStart);
        localStorage.setItem(this.storageKey, JSON.stringify(this.data));
    }

    updateSession() {

        const now = Date.now();
        const lastSessionTime = this.data.lastSessionTime;

        if (!lastSessionTime || now - lastSessionTime > this.sessionTimeout) {
            this.data.sessions.push(now)
        }

        this.data.lastSessionTime = now;
    }

    incrementPageViews() {
        this.data.pageViews.push(Date.now())
    }

    getMetrics() {

        const recency = this.getRecency();
        const frequency = this.getFrequency();
        const volume = this.getVolume();

        const recencyScore = this.getRecencyScore(recency);
        const frequencyScore = this.getFrequencyScore(frequency);
        const volumeScore = this.getVolumeScore(volume);

        return {
            recency,
            frequency,
            volume,
            recencyScore,
            frequencyScore,
            volumeScore
        };
    }

    sendMetrics(metrics) {

        if (!gtag) {
            console.log('Could not send metrics, GTAG is not defined')
            return;
        }

        gtag('set', 'user_properties', {
            recency: metrics.recency,
            frequency: metrics.frequency,
            volume: metrics.value,
            recency_score: metrics.recencyScore,
            frequency_score: metrics.frequencyScore,
            volume_score: metrics.volumeScore
        });

        gtag('event', 'rfv', {});
    }


    getRecency() {

        if (!this.data.lastVisit) {
            return - 1;
        }

        const today = new Date();
        const lastVisitDate = new Date(this.data.lastVisit);

        return Math.floor((today - lastVisitDate) / (1000 * 60 * 60 * 24));
    }

    getFrequency() {
        return this.data.sessions.length;
    }

    getVolume() {
        return this.data.pageViews.length;
    }

    getRecencyScore(recency) {

        let score;

        if (recency === -1) {
            score = 1;
            return score;
        }
        
        let recencyScores = this.params.recencyScores;
        
        for (let i = 0; i < recencyScores.length; i++)
            {
                if (recency >= recencyScores[i][0] && recency <= recencyScores[i][1]){
                    score = i+1;
                }
            }

        /*
        if (recency <= 3) {
            score = 3;
        } else if (recency <= 7) {
            score = 2
        } else if (recency > 7) {
            score = 1;
        }
        */

        return score;
    }

    getFrequencyScore(frequency) {

        let score;
        let frequencyScores = this.params.frequencyScores;

        for (let i = 0; i < frequencyScores.length; i++)
        {
            if (frequency >= frequencyScores[i][0] && frequency <= frequencyScores[i][1]){
                score = i+1;
            }
        }

        /*
        if (frequency <= 2) {
            score = 1;
        } else if (frequency <= 5) {
            score = 2
        } else if (frequency > 5) {
            score = 3
        }
        */

        return score;
    }

    getVolumeScore(volume) {

        let score;
        let volumeScores = this.params.volumeScores;

        for (let i = 0; i < volumeScores.length; i++)
        {
            if (volume >= volumeScores[i][0] && volume <= volumeScores[i][1]){
                score = i+1;
            }
        }

        /*
        if (volume <= 5) {
            score = 1;
        } else if (volume <= 15) {
            score = 2
        } else if (volume > 15) {
            score = 3
        }
        */

        return score;
    }
}

export {
    RFVTracker as default
};
