class LinksTracker {

  constructor(options) {
    var defaults = {
      containerSelector: '.links-tracker-container'
    };
    this.params = Object.assign({}, defaults, options || {});
    this.containers = document.querySelectorAll(this.params.containerSelector);
    this.addEvents();
  }

  addEvents() {
    for (var i = 0; i < this.containers.length; i++) {
      var container = this.containers[i];
      var area = container.dataset.area;
      if (area) {
        this.addTrackEvent(container, area);
      }
    }
  }

  addTrackEvent(container, area) {
    container.addEventListener("click", function(event){
        if (event.target.tagName === 'A') {
            window.gtag('event', area, {
                'event_category': 'Internal Link',
                'event_label': event.target.pathname
            });
        }
    });
  }
}

export {
  LinksTracker as
  default
};
